import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  biggerText: 'bigger-text',
  compact: 'compact',
  footer: 'footer',
  narrow: 'narrow',
  redLinks: 'red-links',
  expanderList: 'expander-list',
  eventHeader: 'event-header',
  housingProjectFAQBlock: 'housing-project-faq-block',
  housingProjectContactForm: 'housing-project-contact-form',
  housingProjectImageWithText: 'housing-project-image-with-text',
  inspoFullWidthMediaIntroBlock: 'inspo-full-width-media-intro-block',
  globalWarningBlock: 'global-warning-block'
};

const RichText = ({ html, themes, className }) => {
  return !html ? null : (
    <div
      className={cn(
        'rich-text',
        className,
        themes.map(theme => `rich-text--${theme}`)
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

RichText.propTypes = {
  html: PropTypes.string,
  themes: arrayOfObjectValues(themes),
  className: PropTypes.string
};

RichText.propTypesMeta = {
  themes: 'exclude',
  className: 'exclude'
};

RichText.defaultProps = {
  themes: []
};

RichText.themes = themes;

export default RichText;
