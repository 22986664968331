import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentWrapper from 'components/content-wrapper';
import Heading from 'components/heading';
import Image from 'components/image';
import LinkList from 'components/link-list';
import Text from 'components/text';
import EstimatedReadingTime from 'components/estimated-reading-time';
import ColoredFrame from 'components/colored-frame';

const themes = {
  green: 'green',
  yellow: 'yellow'
};

const ArticleHeader = ({
  title,
  theme,
  image,
  isFullWidthImage,
  ingress,
  linkList,
  metadata,
  estimatedReadingTime,
  coloredFrame
}) => {
  return (
    <div className="article-header">
      {isFullWidthImage ? (
        <div className="article-header__full-width">
          <div className="article-header__full-width__image-container">
            {image && (
              <Image {...image} className="article-header__full-width__image" />
            )}
            <ContentWrapper className="article-header__full-width__content">
              <div className="article-header__full-width__content__left article-header__left-content">
                <div
                  className={cn('article-header__full-width__title', {
                    [`article-header__full-width__title--${theme}`]: theme
                  })}
                >
                  <Heading level={1} theme={Heading.themes.h1}>
                    {title}
                  </Heading>
                </div>
              </div>
            </ContentWrapper>
          </div>
          <ContentWrapper className="article-header__full-width__content">
            <div className="article-header__left-content">
              <div className="article-header__ingress">
                <Text theme="ingress">{ingress}</Text>
              </div>
              {estimatedReadingTime && (
                <div className="article-header__readingtime">
                  <EstimatedReadingTime {...estimatedReadingTime} />
                </div>
              )}
              {metadata && metadata.length > 0 && (
                <div className="article-header__metadata">
                  {metadata.map(text => (
                    <div className="article-header__metadata-item" key={text}>
                      {text}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {linkList && (
              <div className="article-header__links">
                <LinkList
                  themes={[LinkList.themes.big, LinkList.themes.columns]}
                  {...linkList}
                />
              </div>
            )}
          </ContentWrapper>
        </div>
      ) : (
        <ContentWrapper>
          <div
            className={cn('article-header__content', {
              'article-header__content--no-image': !image
            })}
          >
            <div className="article-header__left-content">
              <div className="article-header__heading">
                <Heading level={1} theme={Heading.themes.h1}>
                  {title}
                </Heading>
              </div>
              {estimatedReadingTime && (
                <div className="article-header__readingtime">
                  <EstimatedReadingTime {...estimatedReadingTime} />
                </div>
              )}
              {metadata && metadata.length > 0 && (
                <div className="article-header__metadata">
                  {metadata.map(text => (
                    <div className="article-header__metadata-item" key={text}>
                      {text}
                    </div>
                  ))}
                </div>
              )}

              <div className="article-header__ingress">
                <Text theme="ingress">{ingress}</Text>
              </div>
              {coloredFrame && (
                <div className="article-header__colored-frame">
                  <ColoredFrame {...coloredFrame} />
                </div>
              )}
            </div>
            {image && (
              <>
                <div className="article-header__right-content">
                  <div className="article-header__image">
                    <Image {...image} />
                  </div>
                </div>
                <div className="article-header__corner" />
              </>
            )}
          </div>

          {linkList && (
            <div className="article-header__links">
              <LinkList
                themes={[LinkList.themes.big, LinkList.themes.columns]}
                {...linkList}
              />
            </div>
          )}
        </ContentWrapper>
      )}
    </div>
  );
};

ArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)),
  image: PropTypes.exact(Image.propTypes),
  isFullWidthImage: PropTypes.bool,
  ingress: PropTypes.string,
  linkList: PropTypes.exact(LinkList.propTypes),
  metadata: PropTypes.arrayOf(PropTypes.string),
  estimatedReadingTime: PropTypes.exact(EstimatedReadingTime.propTypes),
  coloredFrame: PropTypes.exact(ColoredFrame.propTypes)
};

ArticleHeader.defaultProps = {
  metadata: [],
  theme: themes.green
};

export default ArticleHeader;
