import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  background: 'background',
  stretch: 'stretch'
};

const Image = ({
  alt,
  className,
  srcSet,
  src,
  width,
  height,
  theme,
  blendMode,
  isSvg
}) => {
  const image = (
    <img
      alt={alt ? alt : ''}
      className={cn('image', { [`image--${theme}`]: theme }, className)}
      loading="lazy"
      fetchPriority="auto"
      sizes="auto"
      width={width}
      height={height}
      srcSet={srcSet}
      src={src}
      style={isSvg ? { width } : undefined}
    />
  );

  return theme === themes.background ? (
    <div
      className={cn('image__background-wrapper', {
        'image__background-wrapper--blend-mode': blendMode
      })}
      style={{ backgroundImage: `url(${src})` }}
    >
      {image}
    </div>
  ) : (
    image
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  srcSet: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)),
  blendMode: PropTypes.bool,
  isSvg: PropTypes.bool
};

Image.propTypesMeta = {
  className: 'exclude',
  theme: 'exclude',
  blendMode: 'exclude'
};

Image.themes = themes;

export default Image;
