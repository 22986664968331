import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Image from 'components/image';
import Video from 'components/video';
import Link from 'components/link';
import InspoAnimatedTitle from 'components/inspo-animated-title';
import ContentWrapper from 'components/content-wrapper';

const InspoHeader = ({ isArticlePage, image, video, title, pageLink }) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setIsMounted(true), 2000);
  }, []);
  return (
    <div
      className={cn('inspo-header', {
        'inspo-header--is-article-page': isArticlePage
      })}
    >
      {video && (
        <div className="inspo-header__video-container">
          <Video videoClassName="video--in-inspo-header" {...video}></Video>
        </div>
      )}
      {image && !video && (
        <div className="inspo-header__image">
          <Image theme={Image.themes.background} {...image}></Image>
        </div>
      )}
      <div
        className={cn('inspo-header__content', {
          'inspo-header__content--space-between': pageLink
        })}
      >
        {pageLink && (
          <div className="inspo-header__page-link-wrapper">
            <Link
              {...pageLink}
              text={`| ${pageLink?.text}`}
              theme={Link.themes.inspoHeader}
            />
          </div>
        )}
        {title && (
          <ContentWrapper className="inspo-header__title">
            <InspoAnimatedTitle {...title} isMounted={isMounted} />
          </ContentWrapper>
        )}
      </div>
    </div>
  );
};

InspoHeader.propTypes = {
  isArticlePage: PropTypes.bool,
  image: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(Video.propTypes),
  title: PropTypes.exact(InspoAnimatedTitle.propTypes),
  pageLink: PropTypes.exact(Link.propTypes)
};

InspoHeader.propTypesMeta = {
  isArticlePage: 'exclude'
};

export default InspoHeader;
