import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Collapse from 'react-tiny-collapse';

import MenuContext from 'contexts/menu-context';
import useClickAbove from 'hooks/use-click-above';

import Link from 'components/link';
import TabTrapper from 'components/tab-trapper';
import Button from 'components/button';
import Icon from 'components/icon';

const themes = {
  dark: 'dark',
  light: 'light'
};

const HousingProjectMenu = ({
  mainLink,
  links,
  button,
  backToFrontpageLink,
  isArticlePage,
  theme = themes.dark
}) => {
  const {
    isMainMenuOpen,
    setShouldHideMainMenu,
    globalWarningBlockHeigth
  } = useContext(MenuContext);

  const [isOpen, setIsOpen] = useState(false);
  const [shouldHideBaseMenu, setShouldHideBaseMenu] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const prevScrollPosRef = useRef(0);
  const menuRef = useRef();
  const menuContainerRef = useRef(false);
  const menuAnimTimeoutRef = useRef(false);

  useClickAbove(menuRef, () => {
    setIsOpen(false);
    setShouldHideBaseMenu(false);
  });

  const toggleIsAnimating = () => {
    if (menuAnimTimeoutRef.current) clearTimeout(menuAnimTimeoutRef.current);

    setIsAnimating(true);

    menuAnimTimeoutRef.current = setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  };

  const toggle = () => {
    toggleIsAnimating();

    setIsOpen(prev => {
      if (typeof document !== 'undefined') {
        if (prev) {
          document.body.style.overflow = 'auto';
        } else {
          document.body.style.overflow = 'hidden';
        }
      }
      return !prev;
    });
  };

  const handleScroll = useCallback(() => {
    if (isMainMenuOpen) return;
    if (globalWarningBlockHeigth !== '0px') return;

    const currentScrollPos = window.scrollY;
    const isScrollingDown = currentScrollPos > prevScrollPosRef.current;

    toggleIsAnimating();

    setShouldHideBaseMenu(prev => {
      if (isScrollingDown && !prev) {
        return true;
      } else if (!isScrollingDown && prev) {
        return false;
      }
      return prev;
    });

    prevScrollPosRef.current = currentScrollPos;
  }, [prevScrollPosRef, isMainMenuOpen, globalWarningBlockHeigth]);

  useEffect(() => {
    setShouldHideMainMenu(!isOpen && shouldHideBaseMenu);
  }, [shouldHideBaseMenu, isOpen]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        if (window.innerWidth >= 900) {
          setIsOpen(false);
        }
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <div
      ref={menuContainerRef}
      className={cn('housing-project-menu', {
        'housing-project-menu--is-open': isOpen,
        'housing-project-menu--is-article-page': isArticlePage,
        'housing-project-menu--should-hide-menu': shouldHideBaseMenu && !isOpen,
        'housing-project-menu__menu-anim-temp': isAnimating,
        [`housing-project-menu--${theme}`]: theme
      })}
      style={{ '--global-warning-height': globalWarningBlockHeigth }}
    >
      <div ref={menuRef} className="housing-project-menu__content-container">
        <nav
          className="housing-project-menu__content"
          aria-label="secondary menu"
        >
          <TabTrapper isActive={isOpen}>
            <div className="housing-project-menu__header">
              {mainLink && (
                <div className="housing-project-menu__main-link-wrapper">
                  <Link
                    className="housing-project-menu__main-link"
                    color={
                      theme === themes.light
                        ? Link.colors.black
                        : Link.colors.white
                    }
                    animation={Link.animation.expand}
                    {...mainLink}
                  />
                </div>
              )}
              <button
                onClick={toggle}
                className="housing-project-menu__mobile-button"
                aria-label={'Meny'}
              >
                <Icon
                  name="arrow-thin"
                  className={cn('housing-project-menu__mobile-button__icon', {
                    'housing-project-menu__mobile-button__icon-is-open': isOpen
                  })}
                />
              </button>
            </div>
            <div className="housing-project-menu__list-wrapper">
              {links && links.length !== 0 && (
                <div className="housing-project-menu__list-container">
                  <ul className="housing-project-menu__list">
                    {links.map(link => (
                      <li
                        key={link.text}
                        className="housing-project-menu__list-item"
                      >
                        <Link
                          className="housing-project-menu__list-link"
                          animation="expand"
                          color={
                            theme === themes.light
                              ? Link.colors.black
                              : Link.colors.white
                          }
                          {...link}
                        />
                      </li>
                    ))}
                  </ul>
                  {button && (
                    <div className="housing-project-menu__button-container">
                      <Button
                        className="housing-project-menu__button"
                        housingProjectBaseTheme={
                          theme === themes.dark ? 'dark' : 'light'
                        }
                        housingProjectButtonTheme="primary"
                        {...button}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="housing-project-menu__mobile-list">
              <Collapse isOpen={isOpen}>
                {links && links.length !== 0 && (
                  <div className="housing-project-menu__list-container">
                    {backToFrontpageLink && (
                      <a
                        href={backToFrontpageLink.url}
                        className="housing-project-menu__back-to-frontpage-link"
                      >
                        <Icon
                          name="arrow-thin"
                          className="housing-project-menu__back-to-frontpage-link-icon"
                        />
                        <span className="housing-project-menu__back-to-frontpage-link-text">
                          {backToFrontpageLink.text}
                        </span>
                      </a>
                    )}
                    <ul className="housing-project-menu__list">
                      {links.map(link => (
                        <li
                          key={link.text}
                          className="housing-project-menu__list-item"
                        >
                          <Link
                            className="housing-project-menu__list-link"
                            animation="expand"
                            color={
                              theme === themes.light
                                ? Link.colors.black
                                : Link.colors.white
                            }
                            onClick={toggle}
                            {...link}
                          />
                        </li>
                      ))}
                    </ul>
                    {button && (
                      <div className="housing-project-menu__button-container">
                        <Button
                          className="housing-project-menu__button"
                          housingProjectBaseTheme={
                            theme === themes.dark ? 'dark' : 'light'
                          }
                          housingProjectButtonTheme="primary"
                          {...button}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Collapse>
            </div>
          </TabTrapper>
        </nav>
      </div>
    </div>
  );
};

HousingProjectMenu.propTypes = {
  mainLink: PropTypes.exact(Link.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  button: PropTypes.exact(Button.propTypes),
  backToFrontpageLink: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string
  }),
  isArticlePage: PropTypes.bool,
  theme: PropTypes.string
};

HousingProjectMenu.propTypesMeta = {
  isArticlePage: 'exclude',
  theme: 'exclude'
};

HousingProjectMenu.themes = themes;

export default HousingProjectMenu;
