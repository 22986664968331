import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/layout';
import Support from 'components/support';
import ContentWrapper from 'components/content-wrapper';
import ComponentList from 'components/component-list';
import HousingProjectArticleHeader from 'components/housing-project-article-header';
import HousingProjectMenu from 'components/housing-project-menu';
import HousingProjectImageGallery from 'components/housing-project-image-gallery';

const HousingProjectImageGalleryPage = ({
  layout,
  housingProjectArticleHeader,
  menu,
  imageGallery
}) => {
  const header = (
    <HousingProjectArticleHeader
      {...housingProjectArticleHeader}
      theme="dark"
      className="gallery-page"
    />
  );

  return (
    <div className="housing-project-frontpage housing-project-frontpage--dark">
      <Layout
        supportThemes={[Support.themes.fullWidth]}
        fullWidthChildren={header}
        theme={Layout.themes.housingProject}
        {...layout}
      >
        {menu && (
          <HousingProjectMenu
            {...menu}
            isArticlePage
            theme={HousingProjectMenu.themes.dark}
          />
        )}
        <ContentWrapper>
          {imageGallery && <HousingProjectImageGallery {...imageGallery} />}
        </ContentWrapper>
      </Layout>
    </div>
  );
};

HousingProjectImageGalleryPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  housingProjectArticleHeader: PropTypes.exact(
    HousingProjectArticleHeader.propTypes
  ),
  layout: PropTypes.exact(Layout.propTypes),
  menu: PropTypes.exact(HousingProjectMenu.propTypes),
  imageGallery: PropTypes.exact(HousingProjectImageGallery.propTypes)
};

export default HousingProjectImageGalleryPage;
